import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Container } from "../theme/helpers"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { colors, mediaQuery } from "../theme"
import { Link } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { ReactComponent as Pin } from "../images/pin.svg"
import { ReactComponent as Calendar } from "../images/calendar.svg"

const PostContainer = styled.div`
  font-family: Manrope;

  .gatsby-image-wrapper {
    height: 400px;
    width: 100%;
    border-radius: 8px;
  }
`

const Title = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 69px;
  color: #39b54a;
  position: relative;
  margin: 16px 0 32px;

  ${mediaQuery("lg")} {
    font-size: 36px;
    line-height: 30px;
  }
  ${mediaQuery("sm")} {
    font-size: 26px;
    line-height: 30px;
  }
`
const Content = styled.div`
  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    color: black;
    margin: 32px 0 60px;
  }
  strong {
    font-weight: 700;
  }
  ul {
    list-style: circle;
    list-style-position: inside;

    li {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      color: black;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    color: black;
  }

  ${mediaQuery("lg")} {
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    ul {
      li {
        font-size: 14px;
        line-height: 30px;
      }
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
  }
`

const Details = styled.div`
  margin-left: 24px;
  margin-bottom: 32px;

  span {
    display: block;
    margin-bottom: 10px;
  }
`

const EventInfo = styled.div`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 12px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  background-color: hwb(128deg 22% 29% / 90%);
  padding: 6px 10px;
  color: ${colors.white};

  svg {
    fill: ${colors.white};
    height: 18px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 32px;
`

const BlogPost = ({ data }) => {
  const {
    wpEvent: {
      title,
      content,
      featuredImage,
      ACF_events: { endTime, startTime, location, type },
    },
    wpEvent,
    allWpPost: { totalCount: newsCount },
    allWpEvent: { totalCount: eventsCount },
  } = data

  return (
    <>
      <Header newsExists={newsCount > 0} eventsExists={eventsCount > 0} />
      <Seo post={wpEvent} />
      <Container>
        <PostContainer>
          {featuredImage && (
            <GatsbyImage image={getImage(featuredImage.node.localFile)} />
          )}

          <InfoWrapper>
            {startTime || endTime && (<EventInfo>
              <Calendar />
              <span>{`${startTime} - ${endTime}`}</span>
            </EventInfo>)}  

            {location && (<EventInfo>
              <Pin />
              <span>{location}</span>
            </EventInfo>)}
          </InfoWrapper>

          <Title>{title}</Title>
          
          <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
        </PostContainer>
      </Container>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($id: String, $lng: String, $locale: String) {
    allWpPost(filter: { locale: { locale: { eq: $locale } } }) {
      totalCount
    }
    allWpEvent(filter: { locale: { locale: { eq: $locale } } }) {
      totalCount
    }
    wpEvent(id: { eq: $id }) {
      id
      title
      content
      ACF_events {
        endTime
        location
        startTime
        type
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 90)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lng } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`
export default BlogPost
